import { useZodForm } from "ui/use-zod-form";
import { resolver as authResolver } from "api/auth";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormDescription,
  FormMessage,
} from "ui/form";
import { Alert, AlertDescription } from "ui/alert";
import { Input } from "ui/input";
import z from "zod";
import { Button } from "ui/button";
import { Checkbox } from "ui/checkbox";
import { useState } from "react";

const schema = z
  .object({
    username: z.string({
      required_error: "Vul een gebruikersnaam in.",
      invalid_type_error: "Vul een gebruikersnaam in.",
    }),
    name: z.string({
      required_error: "Vul een naam in.",
      invalid_type_error: "Vul een naam in.",
    }),
    email: z
      .string({
        required_error: "Vul een geldig e-mailadres in.",
        invalid_type_error: "Vul een geldig e-mailadres in.",
      })
      .email("Vul een geldig e-mailadres in."),
    password: z
      .string({
        required_error: "Vul een wachtwoord in.",
        invalid_type_error: "Vul een wachtwoord in.",
      })
      .min(6, "Je wachtwoord moet minimaal 6 karakters bevatten"),
    repassword: z.string({
      required_error: "Wachtwoorden komen niet overeen",
      invalid_type_error: "Wachtwoorden komen niet overeen",
    }),
    terms: z.coerce.boolean().refine((bool) => bool, {
      message: "Je moet akkoord gaan met de voorwaarden.",
    }),
    promo: z.boolean().optional(),
  })
  .refine((data) => data.password === data.repassword, {
    path: ["repassword"],
    message: "Wachtwoorden komen niet overeen",
  });

type FormValues = z.infer<typeof schema>;

const SignupForm = () => {
  const [loading, setLoading] = useState(false);
  const form = useZodForm(schema);

  const onSubmit = async (data: FormValues) => {
    const res = await authResolver.signUp(
      data.username,
      data.email,
      data.name,
      data.password,
      data.terms,
      data.promo
    );

    if (!res || "code" in res) {
      setLoading(false);
      let errorMessage = "Er ging iets mis. Controleer je gegevens.";

      switch (res!.code.toLowerCase()) {
        case "emailexists":
          errorMessage = "Het e-mailadres is al in gebruik";
          break;
        case "usernamexists":
          errorMessage = "De gebruikersnaam is al in gebruik";
          break;
        case "validationexception":
          if (
            res?.errors &&
            res.errors.length > 0 &&
            res.errors[0]?.param === "username"
          )
            errorMessage =
              "De gebruikersnaam mag geen spaties en speciale karakters bevatten";
          break;
      }

      return form.setError("root", { message: errorMessage });
    }

    window.location.href = "/login?register=true";
  };

  return (
    <Form {...form}>
      <form
        className="my-2 grid grid-cols-1 gap-4 px-5"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        {form.formState.errors.root !== undefined && (
          <Alert variant="destructive">
            <AlertDescription>
              {form.formState.errors.root.message}
            </AlertDescription>
          </Alert>
        )}

        <FormField
          control={form.control}
          name="username"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">Gebruikersnaam</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">E-mailadres</FormLabel>
              <FormControl>
                <Input type="email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">Naam</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">Wachtwoord</FormLabel>
              <FormControl>
                <Input type="password" {...field} />
              </FormControl>
              <FormDescription>
                Je wachtwoord moet minimaal 6 karakters bevatten
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="repassword"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">Wachtwoord herhalen</FormLabel>
              <FormControl>
                <Input type="password" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="terms"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Checkbox
                  label={
                    <>
                      Ik ga akkoord met de{" "}
                      <a href="/disclaimer" target="_blank">
                        Algemene Voorwaarden
                      </a>{" "}
                      en het{" "}
                      <a href="/privacy-statement" target="_blank">
                        privacy statement
                      </a>{" "}
                      van Reshift
                    </>
                  }
                  onCheckedChange={(checked) => field.onChange(checked)}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="promo"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Checkbox
                  label={
                    <>
                      Ik ontvang graag interessante acties en aanbiedingen van
                      Kieskeurig.nl en{" "}
                      <a
                        href="https://www.reshift.nl/onze-merken"
                        target="_blank"
                      >
                        Reshift Digital{" "}
                      </a>{" "}
                      via e-mail
                    </>
                  }
                  onCheckedChange={(checked) => field.onChange(checked)}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button disabled={loading}>Aanmelden</Button>
      </form>
    </Form>
  );
};

export { SignupForm };
